.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App {
  text-align: center;
  background-color:green;
  display: flex;
  justify-content: center;
  margin-top: 200px;
  background-color: #F49D1A;
  border-radius: 20px;
  box-sizing: border-box;
  height: 650px;
  padding: 20px;
  width: 400px;
}
.form {
  color: #eee;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}
.subtitle {
  color: #eee;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}
 
.label{
  display: block;
  font-size: larger;
  color: white;
  padding: 5px;
}
 
.input{
  font-size: larger;
  padding: 5px;
  margin: 2px;
  
}
.lastname{
  margin: 8px;
}
.btn{
  color: white;
  background-color: red;
  border-radius: 5px;
  font-size: larger;
  display: block;
  padding: 5px;
  margin: 10px auto;
}
.Userdetails{
  position: relative;
}
body {
  align-items: center;
  background-color: #000;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.title {
  color: #eee;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

#schedule{
padding: 20px;

}
.ic1 {
  margin-top: 30px;
}

.ic2 {
  margin-top: 25px;
}









.submit {
  background-color: #08d;
  border-radius: 8px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 28px;
  height: 40px;
  margin-top: 8px;
  width: 100%;
}

.submit:active {
  background-color: #06b;
}


 



