body{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: #ADA2FF;
}
#name{
    margin-bottom: 5px;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 20px;
    width: 200px;
    padding: 5px;
    display: block;
}
#id {
    margin-bottom: 5px;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 20px;
    width: 200px;
    padding: 5px;
    display: block;
}
.label{
    font-size: 0.8em;
    margin-left: 5px;
    margin-bottom: 2px;
    display: block;
}
#card{
    margin-bottom: 15px;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 20px;
    width: 200px;
    padding: 5px;
    display: block;
}
#submitbutton{
    margin-top: 10px;
    border: none;
    height: 30px;
    width: 210px;
    border-radius: 15px;
    display: block;
    cursor: pointer;
}
table, th, td {
    border: 1px solid black;
    
  }
  table{
    margin: 10px;

  }